let awsRegion = 'us-west-2';

export default {
 api_urls: {
   reporting: 'https://djxn1zlcr9.execute-api.us-west-2.amazonaws.com/api',
   geojson: 'https://nidb2hdami.execute-api.us-west-2.amazonaws.com/api',
   overlays: 'https://wd2lp99lb3.execute-api.us-west-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.staging.saas.forwoodsafety.com',
 forwood_id_url: 'https://id.staging.saas.forwoodsafety.com?redirect_uri=https://geoeditor.staging.saas.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.staging.saas.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-west-2_fReqbdQKG',
   userPoolWebClientId: '4v4g1458bdr08eod09qsi0vdvh',
   cookieStorage: {
     domain: '.staging.saas.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'False',
};
